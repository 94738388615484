import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    CURRENT_STORE_ID,
    HAS_PDV_INTEGRATION,
    QRCODE_NUMBER,
    SHOW_READ_QRCODE,
    TABLE_CUSTOMER_CPF,
    TABLE_CUSTOMER_NAME,
    TABLE_NUMBER,
} from 'src/app/shared/consts/domains';
import { JWTPayload } from 'src/app/shared/implements/jwt-payload';
import { Empresa } from 'src/app/shared/models/empresa';
import { MessagesService } from 'src/app/shared/services/messages.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { BreadcrumbOption } from 'src/app/shared/ui/breadcrumb/breadcrumb-option';
import { NullUtil } from 'src/app/shared/utils/null.util';
import { StringUtil } from 'src/app/shared/utils/string.util';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private readonly urlAuth = `${environment.apiUrl}/oauth/token`;
    private readonly urlRevoke = `${environment.apiUrl}/tokens/revoke`;
    private currentAuthSubject: BehaviorSubject<JWTPayload>;

    currentAuth: Observable<JWTPayload>;
    companyId: number;

    constructor(
        private messagesService: MessagesService,
        private toastService: ToastService,
        private navCtrl: NavController
    ) {
        this.currentAuthSubject = new BehaviorSubject<JWTPayload>({} as JWTPayload);
        this.currentAuth = this.currentAuthSubject.asObservable();
    }

    get currentAuthValue(): JWTPayload {
        return this.currentAuthSubject.value;
    }

    get customerCpf() {
        return localStorage.getItem(TABLE_CUSTOMER_CPF);
    }

    set customerCpf(cpf: string) {
        localStorage.setItem(TABLE_CUSTOMER_CPF, StringUtil.removeSpecialChars(cpf));
    }
    get customerName() {
        return localStorage.getItem(TABLE_CUSTOMER_NAME) || '';
    }

    set customerName(customerName: string) {
        localStorage.setItem(TABLE_CUSTOMER_NAME, customerName.toString());
    }

    get hasPdvIntegration(): boolean {
        return (localStorage.getItem(HAS_PDV_INTEGRATION) === 'true');
    }

    set hasPdvIntegration(hasIntegration: boolean) {
        localStorage.setItem(HAS_PDV_INTEGRATION, `${hasIntegration}`);
    }

    get readQrCode() {
        return localStorage.getItem(SHOW_READ_QRCODE) || false;
    }

    set readQrCode(qrCode: any) {
        localStorage.setItem(SHOW_READ_QRCODE, qrCode);
    }

    get qrCodeNumber() {
        return localStorage.getItem(QRCODE_NUMBER) || null;
    }

    set qrCodeNumber(qrcode: string) {
        localStorage.setItem(QRCODE_NUMBER, qrcode);
    }

    get tableNumber() {
        return localStorage.getItem(TABLE_NUMBER) || null;
    }

    set tableNumber(tableNumber: string) {
        localStorage.setItem(TABLE_NUMBER, tableNumber.toString());
    }

    /**
     * Retorna o Store da ID da respectiva loja.
     */
    getStoreId(): string {
        return localStorage.getItem(CURRENT_STORE_ID) || null;
    }

    initBreadCrumb(): BreadcrumbOption[] {
        return [{ label: this.tableNumber ? 'Cardápio' : 'Home', link: 'home'}];
    }

    isAuthUrl(url: string) {
        return url === this.urlAuth || url === this.urlRevoke;
    }

    /**
     * Caso uma respectiva loja não esteja identificada, a mesma é redirecionada
     * para a notificação de empresa não encontrada.
     * @param empresa Empresa
     */
    hasIdentifiedCompany(company: Empresa) {
        if (!NullUtil.isSet(company) || Object.keys(company).length === 0) {
            this.navCtrl.navigateRoot(['/store-not-found']);
        }
    }

    /**
     * Redireciona o usuário para a página de login salvando a rota de destino para que a mesma seja acessada
     * após o usuário se autenticar.
     * @param url string
     */
    loginRedirect(url: string, storeId: string) {
        this.toastService.warning(this.messagesService.getMessage('MSG.AVISO.005'));
        this.navCtrl.navigateRoot(
            [`${storeId}/store/login`, { storeId, returnTo: `${storeId}/store/${url}` }],
            { animated: true, animationDirection: 'forward', replaceUrl: true }
        );
    }

    /**
     * Remove o número de QR Code Armazenado.
     */
    removeQrCodeNumber() {
        localStorage.removeItem(QRCODE_NUMBER);
    }

    /**
     * Remove o estado de leitura do qrcode armazenado
     */
    removeReadQrCode() {
        localStorage.removeItem(SHOW_READ_QRCODE);
    }

    /**
     * Remove o número da mesa armazenado.
     */
    removeTableNumber() {
        localStorage.removeItem(TABLE_NUMBER);
    }

    /**
     * Atribui o código de identificação da loja em LocalStorage.
     * @param storeId string
     */
    setStoreId(storeId: string) {
        localStorage.setItem(CURRENT_STORE_ID, storeId);
    }

}
