import { SolicitacaoDeFechamentoDaComanda } from 'src/app/shared/models/solicitacao-de-fechamento-da-comanda';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { ENTRIES_BY_PAGE } from 'src/app/shared/consts/domains';
import { OrderOptions } from 'src/app/shared/enums/order-options.enum';
import { CardapioDaMesa } from 'src/app/shared/implements/cardapio-da-mesa';
import { CatalogoDePersonalizacoes } from 'src/app/shared/implements/catalogo-de-personalizacoes';
import { ItemDaMesa } from 'src/app/shared/implements/item-da-mesa';
import { ResultSet } from 'src/app/shared/implements/result-set';
import { CategoriaDoProduto } from 'src/app/shared/models/categoria-do-produto';
import { ComandaAtiva } from 'src/app/shared/models/comanda-ativa';
import { ConfiguracaoDePizzaria } from 'src/app/shared/models/configuracao-de-pizzaria';
import { Empresa } from 'src/app/shared/models/empresa';
import { Produto } from 'src/app/shared/models/produto';
import { StatusDoPedido } from 'src/app/shared/models/situacao-do-pedido';
import { StatusDaLoja } from 'src/app/shared/models/status-da-loja';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    companyId: number;

    private readonly criteriaSeparator = '@@';
    private readonly api = environment.apiUrl;
    private readonly endpoint = 'store';

    constructor(private http: HttpClient) {}

    /**
     * Confirma e retorna se todas as contas da mesa foram fechadas.
     * @param table string
     * @returns Observable<boolean>
     */
    areAllAccountsClosed(table: string) {
        return this.http.get<boolean>(
            `${this.api}/${this.endpoint}/order-controls/${this.companyId}/all-accounts-closed/${table}`
        ).pipe(first());
    }

    /**
     * Obtem o cadastro de um produto de uma respectiva loja.
     * @param companyId number
     * @param productId number
     * @returns Observable<Produto>
     */
    findProductById(productId: number) {
        return this.http.get<Produto>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/product/${productId}`
        ).pipe(first());
    }

    /**
     * Retorna todas as comandas ativas de uma respectiva mesa.
     * @param table string
     * @returns Observable<ComandaAtiva[]>
     */
    getActiveOrderControlsByTable(table: string) {
        return this.http.get<ComandaAtiva[]>(`${this.api}/${this.endpoint}/order-controls/${this.companyId}/actives/${table}`)
            .pipe(first());
	}

    /**
     * Lista todos os itens da comanda de uma respecitva mesa.
     * @param table string
     * @returns Observable<ItemDaMesa[]>
     */
    getCurrentOrderControlItems(table: string): Observable<ItemDaMesa[]> {
        return this.http.get<ItemDaMesa[]>(`${this.api}/${this.endpoint}/order-controls/${this.companyId}/table/${table}`);
    }

    /**
     * Lista todas as personalizações de um respectivo produto.
     * @param productId number
     * @returns Observable<CatalogoDePersonalizacoes>
     */
    getCustomizationCatalogByProduct(productId: number) {
        return this.http.get<CatalogoDePersonalizacoes>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/product/${productId}/customizations`
        ).pipe(first());
    }

    /**
     * Obtem o estado atual de um respectivo pedido.
     * @param orderId number
     * @returns Observable
     */
    getOrderState(orderId: number) {
        return this.http.get<StatusDoPedido>(`${this.api}/${this.endpoint}/order/${orderId}`)
            .pipe(first());
    }

    
    /**
     * Obtem as configurações de pizzaria de uma respectiva empresa.
     * @param storeId: string
     * @returns Observable<ConfiguracaoDePizzaria>
     */
    getPizzaConfig() {
        return this.http.get<ConfiguracaoDePizzaria>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/configuration/pizza`
        ).pipe(first());
    }

    /**
     * Obtem o cadastro da empresa.
     * @returns Observable<Empresa>
     */
    getStoreById(storeId: string) {
        return this.http.get<Empresa>(`${this.api}/${this.endpoint}/${storeId}`).pipe(first());
    }

    /**
     * Retorna o menu de uma respectiva empresa.
     * @returns Observable<CardapioDaMesa[]>
     */
    getTableMenuByCompany() {
        return this.http.get<CardapioDaMesa[]>(`${this.api}/${this.endpoint}/order-controls/${this.companyId}/menu`)
            .pipe(first());
    }

    /**
     * Obtem o total de comandas ativas em uma respectiva mesa de uma respectiva empresa.
     * @param table string
     * @returns Observable<number>
     */
    getTotalCurrentOrderControlItems(table :string) {
        return this.http.get<Number>(`${this.api}/${this.endpoint}/order-controls/count/${this.companyId}/table/${table}`)
            .pipe(first());
    }

    /**
     * Obtem o estado de abertura da loja de uma respectiva empresa.
     * @returns Observable<StatusDaLoja>
     */
    getWorkingState(storeId: string) {
        return this.http.get<StatusDaLoja>(`${this.api}/${this.endpoint}/${storeId}/work/state`)
            .pipe(first());
    }

    /**
     * * Obtem o estado de abertura da loja de uma respectiva empresa com a situação do Pedidio.
     * @param orderId number
     * @returns Observable<StatusDaLoja>
     */
    getWorkingAndOrderState(orderId: number) {
        return this.http.get<boolean>(
            `${this.api}/${this.endpoint}/work/state/order/${orderId}`
        ).pipe(first());
    }

    /**
     * Lista todas as categorias ativas de uma respectiva empresa.
     * @returns Observable<CategoriaDoProduto[]>
     */
    listCategories() {
        return this.http.get<CategoriaDoProduto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/categories`
        ).pipe(first());
    }

    /**
     * Lista todos os produtos do catálogo disponíveis.
     * @param categoryId number
     * @returns Observable<Produto[]>
     */
    listCatalogProductsByCategory(categoryId: number) {
        return this.http.get<Produto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/products/category/${categoryId}`
        ).pipe(first());
    }
    /**
     * Lista todas as categorias em destaque de uma respectiva empresa.
     * @returns Observable<CategoriaDoProduto[]>
     */
    listSpotlightCategories() {
        return this.http.get<CategoriaDoProduto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/spotlight/categories`
        ).pipe(first());
    }

    /**
     * Lista as subcategorias de uma respectiva categoria de produto.
     * @param categoryId number
     * @returns Observable<CategoriaDoProduto[]>
     */
    listSubcategories(categoryId: number)  {
        return this.http.get<CategoriaDoProduto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/category/${categoryId}/subcategories`
        ).pipe(first());
    }
    /**
     * Lista todos os produtos em destaque de uma respectiva empresa de forma aleatória.
     * @returns Observable<Produto[]>
     */
    listTempSpotlightProducts() {
        return this.http.get<Produto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/spotlight/products/temp`
        ).pipe(first());
    }
    
    /**
     * Identifica se a loja está aberta ou fechada
     * @returns Observable<boolean>
     */
    storeIsOpened(): Observable<boolean> {
        return this.http.get<boolean>(`${this.api}/${this.endpoint}/is-opened`)
            .pipe(first());
    }

    /**
     * 
     * @param criterias string[]
     * @param pageNumber number
     * @param orderBy string[]
     * @param sortDirection string
     * @param entriesByPage string
     * @returns Observable<ResultSet<Produto>>
     */
    paginateCatalogProduct(criterias: string[], pageNumber: number, orderBy?: string[],
        sortDirection?: string, entriesByPage?: string) {
        const searchCriteria = [];
        searchCriteria.push('empresaId:' + this.companyId);
        searchCriteria.push('categoriaAtiva:' + 1);
        searchCriteria.push('excluido:' + 0);

        if (criterias.length > 0)
            criterias.forEach(criteria => searchCriteria.push(criteria));
        
        const params = new HttpParams()
            .append('search', searchCriteria.join(this.criteriaSeparator))
            .append('numeroPagina', pageNumber > 0 ? `${pageNumber - 1}` : '0')
            .append('sortBy', orderBy ? orderBy.join(',') : '')
            .append('sortDirection', sortDirection || OrderOptions.ASCENDING)
            .append('entriesByPage', entriesByPage || ENTRIES_BY_PAGE);

        return this.http.get<ResultSet<Produto>>(
            `${this.api}/${this.endpoint}/catalog/${this.companyId}/products/filter`,
            { params }
        ).pipe(first());
    }

    /**
     * Salva uma nova requisição de fechamento na comanda de uma respectiva mesa.
     * @param request SolicitacaoDeFechamentoDaComanda
     * @returns Observable<void>
     */
    requestOrderControlClosement(request: SolicitacaoDeFechamentoDaComanda) {
        return this.http.post<void>(
            `${this.api}/${this.endpoint}/order-controls/${this.companyId}/request/closements`,
            request
        );
    }
}
