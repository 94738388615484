import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public controller: ToastController) { }

    /**
     * Exibe um toast com mensagem de erro.
     * @param msg string
     */
    async error(msg: string) {
        this.show(msg, 'danger');
    }

    /**
     * Exibe um toast com mensagem de aviso.
     * @param msg string
     */
    async info(msg: string) {
        this.show(msg, 'info');
    }

    /**
     * Exibbe uma mensagem de toast com mensagem e cor personalizados.
     * @param msg string
     * @param cor string
     */
    async show(msg: string, cor: string) {
        const toast = await this.controller.create({
            message: msg,
            color: cor,
            duration: 2000
        });
        toast.present();
    }

    /**
     * Exibe um toast com mensagem de sucesso.
     * @param msg string
     */
    async success(msg: string) {
        this.show(msg, 'success');
    }

    /**
     * Exibe um toast com mensagem de erro.
     * @param msg string
     */
    async warning(msg: string) {
        this.show(msg, 'warning');
    }

}
