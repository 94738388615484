export const environment = {
    production: true,
    envName: 'production',
    appId: 'br.com.pedidoagora.cardapiodigital',
    apiUrl: 'https://atosapi.com.br',
    googleMapsApiKey: 'AIzaSyC-RNeDiv9-CfMnmmQRummyrM-OMF_LLSY',
    googleMapsGeocoderApiKey: 'AIzaSyC-RNeDiv9-CfMnmmQRummyrM-OMF_LLSY',
    googleMapsUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
    viaCepUrl: 'https://viacep.com.br',
    externalDomains: [
        'viacep.com.br/ws',
        'maps.googleapis.com/maps/api/geocode/json'
    ],
    version: '1.1.7'
};
