import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { SEPERATED_LOCATION, TABLE_LABEL, VENDOR } from 'src/app/shared/consts/domains';

import { NullUtil } from 'src/app/shared/utils/null.util';
import { StringUtil } from 'src/app/shared/utils/string.util';

import { ResourceService } from './resource.service';

import { Empresa } from 'src/app/shared/models/empresa';
import { EmpresaConfiguracao } from 'src/app/shared/models/empresa-configuracao';

@Injectable({
    providedIn: 'root'
})
export class EmpresaService extends ResourceService<Empresa> {

    private vendorSubject: BehaviorSubject<Empresa>;

    constructor(http: HttpClient) {
        super(http, 'empresas');
        this.vendorSubject = new BehaviorSubject<Empresa>(
            JSON.parse(localStorage.getItem(VENDOR)) || {} as Empresa
        );
    }

    get configuracoes(): EmpresaConfiguracao[] {
        return this.vendor.configuracoesDaEmpresa;
    }

    get separatedLocation(): boolean {
        return (localStorage.getItem(SEPERATED_LOCATION) === 'true');
    }

    set separatedLocation(separatedLocation) {
        localStorage.setItem(SEPERATED_LOCATION, `${separatedLocation}`);
    }

    get tableLabel() {
        return localStorage.getItem(TABLE_LABEL);
    }

    set tableLabel(tableLabel: string) {
        localStorage.setItem(TABLE_LABEL, tableLabel);
    }

    // tslint:disable-next-line: adjacent-overload-signatures
    get vendor() {
        return this.vendorSubject.value || JSON.parse(sessionStorage.getItem(VENDOR));
    }

    /**
     * Obtem uma empresa a partir do ID da loja web.
     * @param storeId string
     */
    findCompanyByStoreId(storeId: string){
        return this.http.get<Empresa>(`${this.api}/portal/empresas/${storeId}`).pipe(take(1));
    }

    /**
     * Obtem um registro de configuração de uma respectiva empresa a partir do nome da configuração.
     * @param storeId string
     * @param configName string
     */
    findCompanyConfigByName(storeId: string, configName: string): Observable<EmpresaConfiguracao> {
        return this.http.get<EmpresaConfiguracao>(
            `${this.api}/portal/${this.endpoint}/${storeId}/configuracao/${configName}`
        ).pipe(take(1));
    }

    /**
     * Obtem o registro de uma respectiva empresa.
     */
    getCompany() {
        return this.http.get<Empresa>(`${this.api}/${this.endpoint}/${this.companyId}/`);
    }

    /**
     * Obtem os registros das filiais da empresa
     * @param companyId number
     */
    getCompanyBranches(companyId: number): Observable<Empresa[]> {
        return this.http.get<Empresa[]>(`${this.api}/${this.endpoint}/${companyId}/obter-filiais`);
    }

    getBackground(company: Empresa) {
        if (!StringUtil.isBlank(company.planoDeFundoStore)) {
            return {
                'background-image': `url("${company.planoDeFundoStore}")`
            };
        }

        return {
            'background-image': `url("${company.segmento.caminhoFoto}")`
        };
    }

    getVendorBackground() {
        if (!StringUtil.isBlank(this.vendor.planoDeFundoStore)) {
            return {
                'background-image': `url("${this.vendor.planoDeFundoStore}")`
            };
        }

        return {
            'background-image': `url("${this.vendor.segmento.caminhoFoto}")`
        };
    }

    /**
     * Obtem uma Configuração do Aplicativo da empresa a partir do nome da configuração.
     * @param configuracao string
     */
    getConfig(configuracao: string): EmpresaConfiguracao {
        return this.vendor.configuracoesDaEmpresa.filter(
            config => config.nome === configuracao)[0] || null;
    }

    /**
     * Testa se existe registro do estabelecimento invocado na loja.
     */
    hasSelectedVendor() {
        return this.vendor && this.vendor.id;
    }

    /**
     * Verifica se o Cashback está disponível.
     * @return boolean
     */
     isCashbackAvaliable() {
        const cashbackConfig = this.getConfig('config-ativar-cashback');

        if (!NullUtil.isSet(cashbackConfig)) {
            return false;
        }

        return cashbackConfig.valor === '1';
    }

    /**
     * Atualiza os dados da home: Tempos de entrega, estado da loja (aberto/fechado) e último pedido do cliente.
     * @param clienteId number
     */
    refreshHome(clienteId: number) {
        const params = new HttpParams().append('clienteId', `${clienteId}`);
        return this.http.get(`${this.api}/${this.endpoint}/${this.companyId}/atualizar`, { params });
    }

    /**
     * Registra a empresa vinculada ao vendedor.
     * @param company Empresa
     */
    setVendor(company: Empresa) {
        sessionStorage.setItem(VENDOR, JSON.stringify(company));
        this.vendorSubject.next(company);
    }

}
