import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { of } from 'rxjs';

import {
    APP_SHOPPING_CART,
    CURRENT_STORE_ID,
    FIRST_ORDER,
    TABLE_AVALIABLE_TIME,
    TABLE_CUSTOMER_NAME,
} from 'src/app/shared/consts/domains';
import { Empresa } from 'src/app/shared/models/empresa';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CounterService } from 'src/app/shared/services/counter.service';
import { DataService } from 'src/app/shared/services/data.service';
import { EmpresaService } from 'src/app/shared/services/empresa.service';
import { StoreService } from 'src/app/shared/services/store.service';
import { NullUtil } from 'src/app/shared/utils/null.util';

@Injectable({
    providedIn: 'root'
})
export class CompanyResolverGuard implements Resolve<Empresa> {

    company: Empresa;

    constructor(
        private navCtrl: NavController,
        private authService: AuthService,
        private counterService: CounterService,
        private storeService: StoreService,
        private companyService: EmpresaService,
        private extrasService: DataService) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Empresa> {
        if (route.params && route.params.storeId) {
            this.authService.setStoreId(route.params.storeId);
            const storeId = this.authService.getStoreId();

            if (NullUtil.isSet(storeId) && storeId !== route.params.storeId)
                this.resetData();

            localStorage.setItem(CURRENT_STORE_ID, route.params.storeId);

            if (route.params.tableNumber) {
                this.authService.qrCodeNumber = route.params.tableNumber;
                setTimeout(() => {
                    this.resetData();
                    this.authService.removeTableNumber();
                    this.authService.readQrCode = `${true}`;
                    localStorage.removeItem(TABLE_CUSTOMER_NAME);
                    this.navCtrl.navigateRoot(`read-qrcode`);
                }, TABLE_AVALIABLE_TIME);
            }

            await this.loadCompany(storeId);

            if (!NullUtil.isSet(this.company)) return of(null).toPromise();

            this.companyService.separatedLocation = this.company.configuracoesDaEmpresa.find(
                config => config.nome === 'config-comanda-separada-mesa').valor === '1';
            this.companyService.tableLabel = this.company.configuracoesDaEmpresa
                .find(config => config.nome === 'label-mesa-comanda').valor;
            this.authService.hasPdvIntegration = this.company.configuracoesDaEmpresa.find(
                config => config.nome === 'config-cliente-pdv').valor === '1';

            return of(this.company).toPromise();
        }

        return of(undefined).toPromise();
    }

    private async loadCompany(storeId: string) {
        this.company = await this.storeService.getStoreById(storeId).toPromise()
        .then(company => company)
        .catch(() => null);
    }

    private resetData() {
        this.extrasService.resetMetadata();
        localStorage.removeItem(APP_SHOPPING_CART);
        localStorage.removeItem(TABLE_CUSTOMER_NAME);
        localStorage.removeItem(FIRST_ORDER);
        this.counterService.shoppingCartCounter.next(0);
    }

}
