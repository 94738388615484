import { DataOption } from 'src/app/shared/implements/data-option';

export const FIRST_ORDER = 'cd_first_order';

export const APP_SHOPPING_CART = 'cd_app_shopping_cart';

export const AUTH_CURRENT_USER = 'current_user';

export const CHECK_NEW_ORDER_INTERVAL = 30000;

export const ADDING_NEW_ORDER_CONTROL = 'cd_adding_new_order_control';

export const CRITERIA_MAX = 'Maior';

export const CURRENT_STORE_ID = 'cd_current_cd_id';

export const FREE_VALUE = 'Grátis';

export const HAS_PDV_INTEGRATION = 'cd_has_pdv_integration';

export const MIN_INTERVAL_ORDER_TABLE_RELOAD = 30000;

export const MAX_INTERVAL_RELOAD = 60200;

export const DELAY_NOTIFICATION = 300;

export const DELAY_TRANSITION_REDIRECT = 2000;

export const DELAY_ORDER_ACCOUNT_RELOAD = 15;

export const ENTRIES_BY_PAGE = '35';

export const QRCODE_NUMBER =  'cd_qrcode_number';

export const PIZZA_CALC_CRITERA_AVERAGE: DataOption = {
    label: 'Média',
    value: 'MEDIA'
};

export const PIZZA_CALC_CRITERA_MAX: DataOption = {
    label: 'Maior',
    value: 'MAIOR'
};

export const SCROLLING_DELAY =  500;

export const SEPERATED_LOCATION =  'seperated_location';

export const SHOPPING_CART_LAST_UPDATE =  'shopping_cart_last_update';

export const SHOPPING_CART_MAX_HOURS =  12;

export const SHOW_READ_QRCODE =  'read_qrcode';

export const TABLE_AVALIABLE_TIME =  20 * 60 * 1000;

export const TABLE_CUSTOMER_CPF =  'table_customer_cpf';

export const TABLE_CUSTOMER_NAME =  'table_customer_name';

export const TABLE_LABEL =  'table_label';

export const TABLE_NUMBER =  'table_number';

export const VENDOR =  'cd_vendor';

