import { environment } from 'src/environments/environment';

const PREPOSITIONS = ['da', 'de', 'do', 'das', 'des', 'dos'];

export class StringUtil {

    /**
     * Formata um valor aplicando uma máscara de número decimal sem
     * separador de milhar.
     * @param str string
     */
    static applyDecimalMask = (str: string) => {
        let valor = StringUtil.removeNonNumerics(str);

        if (valor.length === 1) {
            str = '0.0' + valor;
        } else if (valor.length >= 3) {
            if (valor.startsWith('0')) {
                valor = valor.substring(1, valor.length);
            }
            str = `${valor.substring(0, valor.length - 2)}.${valor.substring(valor.length - 2, valor.length)}`;
        }

        return str;
    }

    /**
        * Verifica se a string contêm uma respectiva expressão.
        * @param str string
        */
    static contains = (str: string, value: string) => str.indexOf(value) >= 0;

    /**
     * Verifica se um host está presente na lista de domínios externos interpretados
     * pela aplicação.
     * @param host string
     */
    static externalHost = (host: string) => environment.externalDomains.filter(
        externalHost => host.includes(externalHost)).length > 0

    /**
     * Formata um CEP aplicando a máscara de entrada.
     * @param str string
     */
    static formatCep = (str: string) => `${str.substr(0, 5)}-${str.substr(5, 9)}`;

    /**
     * Formata um CPF aplicando máscara de entrada.
     * @param str string
     */
    static formatCpf = (str: string) =>
        `${str.substring(0, 3)}.${str.substring(3, 6)}.${str.substring(6, 9)}-${str.substring(9, 11)}`

    /**
     * Formata um CNPJ aplicando máscara de entrada.
     * @param str string
     */
    static formatCnpj = (str: string) => `${str.substring(0, 2)}.${str.substring(2, 5)}.${str.substring(5, 8)}` +
        `/${str.substring(8, 12)}-${str.substring(12, 14)}`

    /**
     * Formata o número de um cartão de crédito/débito.
     * @param str: string
     */
    static formatCardNumber = (str: string) =>
        `${str.substring(0, 4)} ${str.substring(4, 8)} ${str.substring(8, 12)} ${str.substring(12, 16)}`.trim()

    /**
     * Formata um Telefone aplicando máscara de entrada.
     * @param str string
     */
    static formatTelephone = (str: string) => (str.length === 11)
        ? `(${str.substring(0, 2)}) ${str.substring(2, 7)}-${str.substring(7, 11)}`
        : `(${str.substring(0, 2)}) ${str.substring(2, 6)}-${str.substring(6, 10)}`;

    /**
     * Verifica se uma mstring está vazia.
     * @param str string
     */
    static isBlank = (str: string) => str === null || str === undefined || str === '' || str === ' ';

    /**
     * Verifica se a sequencia de caracteres são repetidos.
     * @param str string
     */
    static isRepeatedChars = (str: string) => str.split('').every((elem) => elem === str[0]);

    /**
     * Compara a quantidade de caracterees entre duas strings;
     * @param str: string
     * @param lenght: number
     */
    static isLengthEquals = (str: string, length: number) => str.length === length;

    /**
     * Remove todos os caracteres não numéricos presentes em uma string.
     * @param str string
     */
    static removeNonNumerics = (str: string) => str.replace(/\D/g, '');

    /**
     * Remove todos os caracteres especiais de uma string.
     * @param str string
     */
    static removeSpecialChars = (str: string) => str.replace(/[\s\W-]+/g, '');

    /**
     * Remove todos os espaços em branco em uma string.
     * @param str string
     */
    static removeWhitespaces = (str: string) => str.trim().replace(/\s/g, '');

    /**
     * Checks if the first value was included at second value.
     * @param firstVal string
     * @param secondVal string
     */
    static hasValueBetween = (firstVal: string, secondVal: string) => {
        return StringUtil.unaccent(firstVal).toLowerCase().trim().includes(
            StringUtil.unaccent(secondVal).toLowerCase().trim()
        );
    }

    static unaccent = (str: string) => {
        const accentedLetters = `¹²³áàâãäåāăąÀÁÂÃÄÅĀĂĄÆćčç©ĆČÇĐÐèéêёëēĕėęěÉÈÊËЁĒĔĖĘĚ€ğĞıìíîïìĩīĭÌÍÎÏЇÌĨĪĬłŁ
            ńňñŃŇÑòóôõöōŏőøÒÓÔÕÖŌŎŐØŒř®ŘšşșßŠŞȘùúûüũūŭůÙÚÛÜŨŪŬŮýÿÝŸžżźŽŻŹ`;

        const unaccentedLetters = `123aaaaaaaaaAAAAAAAAAAccccCCCDDeeeeeeeeeeEEEEEEEEEEegGiiiiiiiiiIIIIIIIIIlL
            nnnNNNoooooooooOOOOOOOOOOrrRssssSSSuuuuuuuuUUUUUUUUyyYYzzzZZZ`;

        return str.split('').map(letter => {
            const index = accentedLetters.indexOf(letter);
            return index !== -1 ? unaccentedLetters[index] : letter;

        }).join('');
    }

    static upperCaseFirstLetters = (text: string) => {
        let finalText = '';
        text.split(' ').forEach(word => {
            if (!PREPOSITIONS.find(preposition => preposition === word)) {
                finalText = finalText + word.substr(0, 1).toUpperCase() + word.substr(1, word.length) + ' ';
            } else {
                finalText = finalText + word.toLowerCase() + ' ';
            }
        });

        return finalText.trim();
    }

}
