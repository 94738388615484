export const Messages = {
    'MSG.ERRO.PADRAO': '{0}',
    'MSG.ERRO.INTERNO': 'Ocorreu um erro inesperado em nosso servidor. Caso o problema persista, entre em contato com nosso suporte.',
    'MSG.AVISO.001':
        'Não é possível fazer o cancelamento do pedido. Ligue no estabelecimento e pergunte sobre a possibilidade do cancelamento.',
    'MSG.AVISO.002': 'Você deve selecionar um endereço para entrega.',
    'MSG.AVISO.003': 'CEP não encontrado.',
    'MSG.AVISO.004': 'Atenção! Ative sua conta para fazer seus pedidos! Toque aqui para continuar.',
    'MSG.AVISO.005': 'Efetue o login para poder continuar.',
    'MSG.AVISO.006': 'O Troco solicitado não pode ser menor ou igual que o valor do pedido.',
    'MSG.AVISO.007': 'Desculpe, o estabelecimento está fechado neste momento. Tente completar o seu pedido mais tarde.',
    'MSG.AVISO.008': 'A quantidade limite para este item foi atingida!',
    'MSG.AVISO.009': 'Sessão expirada! Efetue o login para poder continuar.',
    'MSG.AVISO.010': 'Sua conta está inativa. Ative-a para prosseguir e finalizar seu pedido.',
    'MSG.AVISO.011': 'Este produto está em falta!',
    'MSG.AVISO.012': 'Sua conta está temporariamente bloqueada. Em caso de dúvidas, entre em contato conosco.',
    'MSG.AVISO.013': 'Você precisa inserir ao menos 3 caracteres para fazer a busca!',
    'MSG.AVISO.014': 'Informe ao menos um produto para incluí-lo em seu pacote.',
    'MSG.AVISO.015': 'Este combo permite incluir até {0} itens.',
    'MSG.AVISO.016': 'Escolha a próxima situação do pedido.',
    'MSG.AVISO.017': 'Ative a localização do seu aparelho para obter sua localização automáticamente.',
    'MSG.AVISO.018': 'Permita o seu navegador a obter sua localização para obter sua localização automáticamente.',
    'MSG.AVISO.019': 'Para fazer pagamento on-line do seu pedido é necessário ter um endereço cadastrado.',
    'MSG.AVISO.026': 'O rodízio deve ter ao menos um item passando em sua mesa.',
    'MSG.AVISO.027': 'Desculpe, um ou mais produtos estão em falta: {0}',
    'MSG.AVISO.028': 'Mesa em fechamento. Solicite a reabertura da mesa.',
    'MSG.AVISO.029': 'Por favor, confirme o número da sua mesa.',
    'MSG.AVISO.033': 'Seu pedido está sendo enviado. Por favor aguarde.',
    'MSG.AVISO.040': 'O sabor "{0}" já foi selecionado.',
    'MSG.AVISO.041': 'Você selecionou um ou mais produtos, deseja salvá-los?',

    'MSG.CONFIRM.001': 'Encontramos seu e-mail. Deseja efetuar o login ou recuperar sua senha?',
    'MSG.CONFIRM.002': 'Deseja limpar o Carrinho e cancelar seu Pedido?',
    'MSG.CONFIRM.003': 'Você já possui uma solicitação ativa. O que deseja fazer?',
    'MSG.CONFIRM.004': 'Deseja remover este endereço?',
    'MSG.CONFIRM.005': 'Alterar endereço? Você não terá nenhum endereço definido como "Principal"!',
    'MSG.CONFIRM.006': 'Deseja mesmo cancelar o pedido?',
    'MSG.CONFIRM.007': 'Deseja mesmo excluir o {0} "{1}"?',
    'MSG.CONFIRM.008': 'Deseja mesmo excluir a {0} "{1}"?',
    'MSG.CONFIRM.009': 'Deseja marcar "{0}" como destaque?',
    'MSG.CONFIRM.010': 'Deseja remover marcação de "{0}" como destaque?',
    'MSG.CONFIRM.011': 'Ao mudar o tipo de valor da personalização todos os valores atuais serão removidos. Deseja continuar?',
    'MSG.CONFIRM.012': 'Deseja mesmo excluir o {0} "{1}" para {2} "{3}"?',
    'MSG.CONFIRM.013': 'Deseja mesmo excluir a {0} "{1}" para {2} "{3}"?',
    'MSG.CONFIRM.014': 'Deseja {0} o pedido nº {1}?',
    'MSG.CONFIRM.015': 'Deseja {0} o cliente "{1}"?',
    'MSG.CONFIRM.016': 'Sua conta foi criada com sucesso! Deseja fazer seu primeiro pedido?',
    'MSG.CONFIRM.017': 'Ao mudar o tipo de fração todos os valores atuais serão removidos. Deseja continuar?',
    'MSG.CONFIRM.018': 'Deseja salvar as mudanças ao mudar de página? Caso não salve, os valores alterados serão perdidos.',
    'MSG.CONFIRM.019': 'Sua conta foi criada com sucesso! Deseja retornar ao carrinho e finalizar seu pedido?',
    'MSG.CONFIRM.020': 'Deseja mesmo {0} o {1} "{2}"?',
    'MSG.CONFIRM.021': 'Deseja mesmo {0} a {1} "{2}"?',
    'MSG.CONFIRM.022': 'Ao cancelar, a personalização do item do pedido será perdida. Deseja continuar?',
    'MSG.CONFIRM.023': 'Deseja abrir a loja agora?',
    'MSG.CONFIRM.024': 'Deseja fechar a loja agora? Ao confirmar, o atendimento a novos pedidos será encerrado.',
    'MSG.CONFIRM.025': 'Deseja mesmo excluir o item "{0}" do carrinho?',
    'MSG.CONFIRM.026':
        'Atenção: O item "{0}" é promocional. Ao removê-lo os brindes também serão removidos do carrinho. Deseja continuar?',
    'MSG.CONFIRM.027': 'Ao cancelar seu pedido não será finalizado. Deseja continuar?',
    'MSG.CONFIRM.028':  'Deseja realizar seu pedido?',
    'MSG.CONFIRM.029':
        'Seu bairro não é coberto por nosso serviço de entrega, deseja continuar? Se sim, o seu pedido poderá ser recusado.',
    'MSG.CONFIRM.030': 'Deseja mesmo remover todos os {0}?',
    'MSG.CONFIRM.031': 'Deseja mesmo remover todas as {0}?',
    'MSG.CONFIRM.032': 'Você está montando uma combinação da promoção "{0}". Ao continuar, ela será perdida. Deseja prosseguir?',
    'MSG.CONFIRM.033': 'Deseja excluir este cartão?',
    'MSG.CONFIRM.034': 'O e-mail ou telefone inserido não corresponde a uma conta. Deseja criá-la agora?',
    'MSG.CONFIRM.036': 'Pagamento recusado pela operadora. O que deseja fazer?',
    'MSG.CONFIRM.037': 'Você não tem nenhum endereço cadastrado. Deseja cadastrar um endereço agora?',
    'MSG.CONFIRM.051': 'Gostaria que o item {0} não seja mais passado por sua mesa?',
    'MSG.CONFIRM.052': 'Está satisfeito? Ao confirmar não vamos mais passar em sua mesa.',
    'MSG.CONFIRM.053': 'Deseja que o item "{0}" não seja mais passado em sua mesa?',
    'MSG.CONFIRM.057': 'Deseja fechar a conta da mesa {0}?',

    'MSG.ERRO.001': '{0} é obrigatório.',
    'MSG.ERRO.002': '{0} é inválido.',
    'MSG.ERRO.003': 'Informe seu nome completo.',
    'MSG.ERRO.004': 'As senhas informadas não conferem.',
    'MSG.ERRO.005': 'A senha deve ter no mínimo 8 caracteres.',
    'MSG.ERRO.006': 'Confirme sua senha.',
    'MSG.ERRO.007': 'Nosso termo de uso não permite usuários com idade inferior a 16 anos.',
    'MSG.ERRO.008': 'Nosso termo de uso não permite usuários com idade superior a 90 anos.',
    'MSG.ERRO.009':
        'O e-mail digitado não se encontra cadastrado no nosso banco de dados. Verifique se o e-mail foi digitado corretamente.',
    'MSG.ERRO.010': 'Usuário não encontrado ou senha inválida!',
    'MSG.ERRO.011': 'Não é possível alterar o status do pedido. Este pedido já foi cancelado ou finalizado!',
    'MSG.ERRO.012': 'Selecione a Forma de Entrega.',
    'MSG.ERRO.013': 'Selecione um de seus endereços para entrega do seu pedido.',
    'MSG.ERRO.014': 'Escolha a forma de pagamento.',
    'MSG.ERRO.015': 'Selecione uma opção de cartão para pagamento.',
    'MSG.ERRO.016': 'Cupom não encontrado.',
    'MSG.ERRO.017': 'Cupom vencido.',
    'MSG.ERRO.018': 'Informe o motivo da desativação da conta.',
    'MSG.ERRO.019': 'Usuário ou senha não encontrado.',
    'MSG.ERRO.020': 'Usuário não autorizado.',
    'MSG.ERRO.021': 'Empresa não autenticada. Faça login novamente!',
    'MSG.ERRO.022': 'Este cupom foi utilizado em outro pedido e não pode ser usado novamente.',
    'MSG.ERRO.023': 'Código de ativação inválido ou solicitação inexistente!',
    'MSG.ERRO.024': 'Erro ao incluir novo endereço.',
    'MSG.ERRO.025': 'Erro ao alterar endereço.',
    'MSG.ERRO.026': 'Erro ao excluir endereço.',
    'MSG.ERRO.027': 'Este endereço já está cadastrado na sua lista!',
    'MSG.ERRO.028': 'O cupom informado não se aplica ao seu pedido!',
    'MSG.ERRO.029': 'Valor inválido para o desconto do cupom!',
    'MSG.ERRO.030': 'Verifique sua conexão.',
    'MSG.ERRO.031': 'Usuário não encontrado. Por favor, verifique seu e-mail.',
    'MSG.ERRO.032': 'Código de solicitção inválido ou solicitação inexistente.',
    'MSG.ERRO.033': 'Sua solicitação expirou e será necessário solicitar uma nova recuperação de senha.',
    'MSG.ERRO.034': 'Selecione uma opção.',
    'MSG.ERRO.035': 'Erro ao obter pedido.',
    'MSG.ERRO.036': 'Este endereço não pode ser excluído: Um ou mais pedidos utilizam este endereço.',
    'MSG.ERRO.037': 'O usuário {0} {1} tem conta ativa e não pode ser excluída.',
    'MSG.ERRO.038': 'Serviço OMNI Delivery indisponível. Tente novamente mais tarde.',
    'MSG.ERRO.039': 'Usuário não encontrado. Verifique seu e-mail e/ou senha.',
    'MSG.ERRO.040': 'Usuário não autorizado. Verifique suas credenciais.',
    'MSG.ERRO.041': 'A personalização deve possuir no mínimo uma opção.',
    'MSG.ERRO.042': 'A personalização deve possuir no mínimo duas opções.',
    'MSG.ERRO.043': 'Já existem {0} produtos em destaque. Remova 1 ou mais para poder adicionar outro(s).',
    'MSG.ERRO.044': 'Erro ao converter data.',
    'MSG.ERRO.045': 'O {0} "{1}" já foi incluso.',
    'MSG.ERRO.046': 'A Personalização deve ter no mínimo um valor definido.',
    'MSG.ERRO.047': 'Este {0} é utilizado um ou mais registros de {1} e não pode ser excluído',
    'MSG.ERRO.048': 'Esta {0} é utilizado um ou mais registros de {1} e não pode ser excluído',
    'MSG.ERRO.049': 'O {0} não pode ser alterado.',
    'MSG.ERRO.050': 'A {0} não pode ser alterada.',
    'MSG.ERRO.051': 'Hora de fechamento deve ser superior à hora de abertura.',
    'MSG.ERRO.052': 'Não foi possível remover o Cupom Utilizado.',
    'MSG.ERRO.053': 'Não foi possível bloquear {0}.',
    'MSG.ERRO.054': 'Não foi possível desbloquear {0}.',
    'MSG.ERRO.055': 'A {0} informada não pode ser usada porque ela pertence a um intervalo de horas existente.',
    'MSG.ERRO.056': 'O bairro "{0}" já tem uma Tarifação vinculada!',
    'MSG.ERRO.057': '{0} não pode ser maior que {1}.',
    'MSG.ERRO.058': 'Esta categoria é utilizada por um ou mais Produtos.',
    'MSG.ERRO.059': 'Selecione ao menos um bairro para vincular à esta tarifação.',
    'MSG.ERRO.060': 'Não é possível criar uma nova tarifa: Empresa trabalha com tarifa única.',
    'MSG.ERRO.061': 'Preencha completamente a Data de Nascimento ou deixe o campo em branco!',
    'MSG.ERRO.062': 'Não foi possível processar sua requisição.',
    'MSG.ERRO.063': 'Serviço indisponível. Tente novamente mais tarde.',
    'MSG.ERRO.065': 'Informe a quantidade (em Kg).',
    'MSG.ERRO.066': 'A personalização de produtos vendidos por fração permite apenas uma opção de valor.',
    'MSG.ERRO.067': 'QR Code inválido. Faça a leitura novamente ou selecione outra forma de entrega!',
    'MSG.ERRO.068': 'Nenhum dado obtido.',
    'MSG.ERRO.069': 'A data {0} não pode ser menor que a data atual.',
    'MSG.ERRO.070': 'Você utilizou este cupom que só pode ser utilizado em um pedido por dia.',
    'MSG.ERRO.071': 'Não foi possível atualizar o seu app. Desinstale o aplicativo e faça uma nova instalação a partir da sua loja.',
    'MSG.ERRO.072': 'A promoção deve possuir ao menos um brinde.',
    'MSG.ERRO.073': 'Selecione ao menos um produto de brinde.',
    'MSG.ERRO.074': 'Já existe uma regra de compra para o produto "{0}".',
    'MSG.ERRO.075': 'A Promoção deve possuir no mínimo uma Regra de Compra.',
    'MSG.ERRO.076': 'A Promoção {0} não está dentro do período de vigência.',
    'MSG.ERRO.077': 'A Promoção {0} não é válida para o dia de hoje.',
    'MSG.ERRO.078': 'A Promoção {0} não aceita cupom de desconto.',
    'MSG.ERRO.079': 'A Promoção {0} não está ativa no momento.',
    'MSG.ERRO.080': 'A Promoção {0} pertence a outra empresa.',
    'MSG.ERRO.082': 'Selecione uma Promoção',
    'MSG.ERRO.083': 'Selecione o brinde.',
    'MSG.ERRO.084': 'Selecione um ou mais brinde(s).',
    'MSG.ERRO.085': 'O endereço de entrega informado não faz parte de nossa área de cobertura!',
    'MSG.ERRO.086': 'Você esqueceu de marcar o campo "Não sou um robô". Marque-o e tente novamente',
    'MSG.ERRO.087': 'Erro ao enviar o {0}.',
    'MSG.ERRO.088': 'Erro ao enviar a {0}.',
    'MSG.ERRO.090': 'Não foi possível completar a ligação.',
    'MSG.ERRO.091': 'Erro ao carregar imagem: {0}.',
    'MSG.ERRO.092': 'Erro ao adicionar {0}.',
    'MSG.ERRO.093': 'Erro ao remover {0}.',
    'MSG.ERRO.094': 'Este município é sede da Empresa e já está automáticamente vinculado aos municípios atendidos.',
    'MSG.ERRO.095': 'E-mail já cadastrado na empresa!',
    'MSG.ERRO.096': 'Pagamento recusado pela operadora. Verifique os dados do seu cartão.',
    'MSG.ERRO.097': '{0} não encontrado.',
    'MSG.ERRO.098': 'Informe ao menos um produto para vincular.',
    'MSG.ERRO.099': 'Ocorreu um erro ao fazer logout: {0}',
    'MSG.ERRO.100': 'Parece que não há internet.',
    'MSG.ERRO.104': 'Bandeira não identificada. Por favor, confira o número do cartão.',
    'MSG.ERRO.109': 'Ocorreu um erro ao {0}: {1}',
    'MSG.ERRO.110': 'Telefone não encontrado. Faça o login com seu e-mail.',
    'MSG.ERRO.111': 'Não foi possível obter o token de notificações. Tente reiniciar o aplicativo.',
    'MSG.ERRO.112': 'Não foi possível registrar o token de notificações. Verifique sua internet ou tente reiniciar o aplicativo.',
    'MSG.ERRO.114': 'Já há um pedido seu sendo processado! Aguarde até {0} minutos e tente novamente!',
    'MSG.ERRO.115': 'Desculpe, não agendamos {0} no dia informado.',
    'MSG.ERRO.116': 'A data e hora de agendamento do pedido não pode ser inferior ao dia atual.',
    'MSG.ERRO.117': 'O horário informado deve ser maior ou igual ao tempo mínimo de preparo da {0}.',
    'MSG.ERRO.118': 'Informe um horário entre {0} e {1} horas.',
    'MSG.ERRO.119': 'Desculpe, o estabelecimento está fechado neste momento. Agende seu pedido.',
    'MSG.ERRO.122': 'A quantidade mínima de {0} é {1}.',
    'MSG.ERRO.125': 'Necessário informar o {0}.',
    'MSG.ERRO.128': 'Bandeira não suportada',
    'MSG.ERRO.133': 'Inclua ao menos um produto para adicionar no carrinho.',
    'MSG.ERRO.135': 'Selecione um tamanho.',
    'MSG.ERRO.136': 'Selecione um ou mais tamanhos.',
    'MSG.ERRO.142': 'Existe mais de uma conta vínculada ao {0}. Com qual conta deseja logar?',
    'MSG.ERRO.148': 'Selecione ao menos {0} opção.',
    'MSG.ERRO.149': 'Selecione ao menos {0} opções.',
    'MSG.ERRO.150': '(a) cliente chamado "{0}" já existe na comanda.',
    'MSG.ERRO.153': 'Desculpe, seu saldo é insuficiente para finalizar este pedido.',
    'MSG.ERRO.154': 'Valor não pode ser maior que o total do seu pedido. Verifique o crédito informado.',
    'MSG.ERRO.158': 'Selecione um ou mais produtos.',
    'MSG.ERRO.159': 'Informe ao menos uma pessoa que participará do rodízio.',
    'MSG.ERRO.162': 'Selecione ao menos uma opção do rodízio.',


    'MSG.INFO.001': 'Nenhum pedido realizado recentemente!',
    'MSG.INFO.002': 'O seu pedido nº {0} foi cancelado por {1}',
    'MSG.INFO.003': 'Nenhum opcional foi selecionado.',
    'MSG.INFO.004': 'Seu link de ativação expirou. Verifique sua caixa de e-mails com o novo link para ativar sua conta.',
    'MSG.INFO.005': 'Seu carrinho está vazio!',
    'MSG.INFO.006': 'Você deve ter ao menos um endereço cadastrado para receber seus pedidos!',
    'MSG.INFO.007': 'Você não tem nenhum endereço definido como "Principal"!',
    'MSG.INFO.008': 'Você incluiu itens no carrinho. Ao continuar, os itens atuais serão substituídos!',
    'MSG.INFO.009': 'Não precisa de troco.',
    'MSG.INFO.010': 'Você precisa selecionar um bairro!',
    'MSG.INFO.015': 'Você não tem mais nenhum cartão cadastrado!',
    'MSG.INFO.016': 'Solicitando Pedido, por favor aguarde...',

    'MSG.SUCESSO.001': 'Conta criada com sucesso!',
    'MSG.SUCESSO.002': 'Um e-mail de recuperação de senha foi enviado para você. Verifique sua Caixa de Entrada.',
    'MSG.SUCESSO.003': 'Logout realizado com sucesso!',
    'MSG.SUCESSO.004': 'O pedido {0} foi atualizado para "{1}", em {2}. Toque para acompanhar.',
    'MSG.SUCESSO.005': 'Nova solicitação enviada! Em instantes você receberá um e-mail para confirmar seu cadastro.',
    'MSG.SUCESSO.006': 'Login realizado com sucesso!',
    'MSG.SUCESSO.007': 'Sua conta foi ativada com sucesso!',
    'MSG.SUCESSO.008': 'Sua conta foi atualizada com sucesso!',
    'MSG.SUCESSO.009': 'Sua conta foi inativada com sucesso! Ela será excluída definitivamente em 30 dias.',
    'MSG.SUCESSO.010': 'Endereço incluído com sucesso!',
    'MSG.SUCESSO.011': 'Endereço alterado com sucesso!',
    'MSG.SUCESSO.012': 'Endereço excluído com sucesso!',
    'MSG.SUCESSO.013': 'Recuperação de senha foi solicitada com sucesso!',
    'MSG.SUCESSO.014': 'Senha atualizada com sucesso!',
    'MSG.SUCESSO.015': 'Pedido enviado com sucesso!',
    'MSG.SUCESSO.016': 'Pedido cancelado com sucesso!',
    'MSG.SUCESSO.017': 'Solicitação de reativação de conta enviada! Em instantes você receberá um e-mail contendo seu código de ativação.',
    'MSG.SUCESSO.018': '{0} salvo com sucesso!',
    'MSG.SUCESSO.019': '{0} salva com sucesso!',
    'MSG.SUCESSO.020': '{0} alterado com sucesso!',
    'MSG.SUCESSO.021': '{0} alterada com sucesso!',
    'MSG.SUCESSO.022': '{0} excluído com sucesso!',
    'MSG.SUCESSO.023': '{0} excluída com sucesso!',
    'MSG.SUCESSO.024': 'Pedido nº {0} {1} com sucesso!',
    'MSG.SUCESSO.025': '{0} bloqueado(a) com sucesso!',
    'MSG.SUCESSO.026': '{0} desbloqueado(a) com sucesso!',
    'MSG.SUCESSO.027': '{0} "{1}" removido com sucesso!',
    'MSG.SUCESSO.028': '{0} "{1}" removida com sucesso!',
    'MSG.SUCESSO.029': '{0} "{1}" adicionado com sucesso!',
    'MSG.SUCESSO.030': '{0} "{1}" adicionada com sucesso!',
    'MSG.SUCESSO.031': 'Impressão de Pedido realizada com sucesso!',
    'MSG.SUCESSO.032': '{0} atualizado(s) com sucesso!',
    'MSG.SUCESSO.033': '{0} atualizada(s) com sucesso!',
    'MSG.SUCESSO.034': '{0} enviado(s) com sucesso!',
    'MSG.SUCESSO.035': '{0} enviada(s) com sucesso!',
    'MSG.SUCESSO.036': 'Item do pedido "{0}" adicionado ao carrinho com sucesso!',
    'MSG.SUCESSO.037': 'Todos os itens do carrinho foram removidos com sucesso!',
    'MSG.SUCESSO.038': 'O Item do pedido e seus respectivos brindes foram removidos com sucesso!',
    'MSG.SUCESSO.039': '{0} enviado com sucesso!',
    'MSG.SUCESSO.040': '{0} excluídos com sucesso!',
    'MSG.SUCESSO.041': '{0} excluídas com sucesso!',
    'MSG.SUCESSO.045': 'Loja {0} com sucesso!',
    'MSG.SUCESSO.050': 'Parabéns! Você ganhou um crédito de {0} que estará disponível para utilização quando seu pedido for finalizado.',
    'MSG.SUCESSO.051': 'Parabéns! Você utilizou seu crédito e poupou {0} na compra do seu pedido.',
    'MSG.SUCESSO.052': 'Rodízio enviado com sucesso!',
    'MSG.SUCESSO.053': 'Rodízio nº {0} {1} com sucesso!',
    'MSG.SUCESSO.054': 'Fechamento da {0} solicitado com sucesso!',
    'MSG.SUCESSO.055': 'Fechamento de {0} do(s) cliente(s) {1} solicitado com sucesso!',

    'MSG.TITULO.001': 'Usuário existente',
    'MSG.TITULO.002': 'Solicitação não confirmada',
};
