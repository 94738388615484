import { environment } from 'src/environments/environment';

export class NullUtil {

    /**
     * Testa se uma respectiva varivável tem valor atribuído
     * @param value any
     */
    static isSet(value: any) {
        return value && value !== null && value !== '' && value !== undefined && value !== {};
    }
}
