import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';

import { CompanyResolverGuard } from 'src/app/shared/guards/company-resolver.guard';

const COMPANY_RESOLVER = {
    company: CompanyResolverGuard
};

const routes: Routes = [
    {
        path: '404',
        loadChildren: () => import('./store-not-found/store-not-found.module').then(
            m => m.StoreNotFoundPageModule
        )
    },
    {
        path: ':storeId/:tableNumber',
        loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule),
        resolve: COMPANY_RESOLVER
    },
    {
      path: ':storeId/table',
      loadChildren: () => import('./table-order/table-order.module').then( m => m.TableOrderPageModule),
      resolve: COMPANY_RESOLVER
    },
    {
        path: 'menu-unavaliable',
        loadChildren: () => import('./menu-unavaliable/menu-unavaliable.module').then( m => m.MenuUnavaliablePageModule)
    },
    {
        path: 'read-qrcode',
        loadChildren: () => import('./read-qrcode/read-qrcode.module').then(m => m.ReadQrcodePageModule)
    },
    {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full'
    },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: NoPreloading,
            initialNavigation: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
